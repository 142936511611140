<template>
  <modal
    :modalId="`modalEditUpload`"
    :modalTitle="`Editar Arquivo`"
    :load="false"
    :typeLg="`modal_lg`"
    @closeModal="closeModal"
  >
    <div class="main">
      <div class="tab-content" id="myTabContent">
        <tabs :tabs="tabList" />
        <upload
          :class="`show active`"
          @uploads="files"
          :id="formData.id"
          :name.sync="formData.name"
          :file.sync="formData.file"
          :description.sync="formData.description"
          :fileName.sync="formData.fileName"
          :idDocumentUpload="
            tabList.find(s => s.tabName.includes('document')).tabName
          "
        />
        <links
          :formData="formData"
          :idLinkDirect="tabList.find(s => s.tabName.includes('link')).tabName"
        />

        <!-- <categoria-upload-vue
        v-if="$route.path === '/arquivos'"
        :trainingCategoryIds.sync="formData.trainingCategoryIds"
        :clear="load"
      /> -->
      </div>
    </div>
    <actions
      :nameButton="`Salvar`"
      :disabled="load"
      @closeModal="closeModal"
      @submitform="submitUpload"
    />
  </modal>
</template>

<script>
import HTTP from "@/api_system";
import { HalfCircleSpinner } from "epic-spinners";

export default {
  props: ["editFileItem"],
  components: {
    upload: () => import("./tabs/uploadFile.vue"),
    links: () => import("./tabs/uploadLink.vue"),
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () =>
      import("@/components/arquivos/files/acoes/botoes_rodape.vue"),
    tabs: () => import("@/components/tabs/modalTabs.vue"),
    HalfCircleSpinner
  },
  data() {
    return {
      load: false,
      cp: "upload",
      file: null,
      formData: {
        name: "",
        file: "",
        description: "",
        link: "",
        trainingCategoryIds: [],
        profiles: [],
        fileName: ""
      },
      tabList: [
        {
          description: "Documento",
          tabName: `${this.id}_document`
        },
        {
          description: "Link Direto",
          tabName: `${this.id}_link`
        }
      ]
    };
  },
  computed: {
    itemFiles() {
      return this.$store.getters.editGetFiles;
    }
  },
  methods: {
    files(event) {
      this.formData.file = event.target.files[0];
      this.formData.fileName = "";
      this.formData.fileName = event.target.files[0].name;
      return event.target.files[0];
    },
    submitUpload() {
      const item = this.editFileItem;
      this.load = true;
      let data = new FormData();

      let { description, link, name, file } = this.formData;

      if (item.hashName !== file) {
        data.append("File", file);
      } else {
        data.append("HashName", item.hashName);
        data.append("FileSize", item.fileSize);
      }

      data.append("Name", name);
      data.append("Id", item.id);
      data.append("Description", description);
      data.append("Url", link);
      data.append(
        "TrainingCategoryIds",
        JSON.stringify(item.trainingCategoryIds)
      );
      data.append("Profiles", JSON.stringify(item.profiles));

      HTTP.post(`services/app/TrainingUpload/CreateOrEdit`, data, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          this.load = false;
          this.closeModal();
          this.toast("Arquivo editado com sucesso", this.$toast);
          this.formData.description = "";
          this.formData.link = "";
          this.formData.name = "";
          this.formData.file = "";
        })
        .catch(err => {
          console.log(err);
          this.load = false;
        });
    },
    closeModal() {
      this.$emit(`closeModal`, `modalEditUpload`);
      this.formData = {
        name: "",
        file: "",
        description: "",
        link: "",
        trainingCategoryIds: this.formData.trainingCategoryIds,
        profiles: this.formData.profiles,
        fileName: "",
        id: "",
        allProfiles: false
      };
    }
  },
  watch: {
    editFileItem: function() {
      this.formData.description = this.editFileItem.description;
      this.formData.name = this.editFileItem.name;
      this.formData.id = this.editFileItem.id;
      this.formData.profiles = this.editFileItem.profiles;
      this.formData.link = this.editFileItem.link;
      this.formData.file = this.editFileItem.hashName;
      this.formData.fileName = this.editFileItem.hashName;
      this.formData.trainingCategoryIds = this.editFileItem.trainingCategoryIds;
    }
  }
};
</script>

<style scoped>
.menu,
.menu-close {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

#checkbox-menu,
#btnClose {
  cursor: pointer;
  position: relative;
  display: block;
  height: 22px;
  width: 30px;
  margin: 0;
}

#btnClose span {
  position: absolute;
  display: block;
  border-radius: 30px;
  background: orange;
  transition: 0.25s ease-in-out;
  width: 36px;
  border: 2px solid orange;
}

#btnClose span:nth-child(1) {
  transform: rotate(-45deg);
  top: 8px;
}

#btnClose span:nth-child(2) {
  opacity: 0;
}

#btnClose span:nth-child(3) {
  transform: rotate(45deg);
  top: 8px;
}

#modalEditUpload .containerUpload {
  max-width: 761px !important;
  width: 760px !important;
}

#modalEditUpload .containerMain {
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

#modalEditUpload .modal-header {
  border: none;
  display: flex;
  flex-direction: row-reverse;
}

.labelTitle {
  color: #a5a6ad !important;
}

#modalEditUpload h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #2474b2;
}

#modalEditUpload .main > div {
  display: flex;
  flex-direction: column;
}

#modalEditUpload .main .checkContainer {
  display: flex;
}

#modalEditUpload .main label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  cursor: pointer;
}

#modalEditUpload .main input {
  background: #ffffff;
  border: 1px solid #98c4e6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
}

.checkContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 10px 0;
}
.checkContainer div label {
  margin-left: 5px;
}

.btn {
  height: 40px;
  width: auto;
  border: none;
  border-radius: 6px;
  align-items: center;
  outline: none;
  width: 120px;
  padding: 0 20px;
}

.btn_secondary {
  color: #2474b2;
  border: #2474b2 1px solid;
}

.btn_default {
  background-color: #f38235;
}

.btn_default:hover {
  background-color: #e0a638;
}

.hoverFolder:hover {
  color: #f38235 !important;
  transform: translateY(-15px);
  -webkit-box-shadow: 5px 7px 10px 1px rgba(0, 0, 0, 0.39);
  box-shadow: 5px 7px 10px 1px rgba(0, 0, 0, 0.39);
}

.nav-link {
  color: #98c4e6;
  font-weight: 700;
  font-size: 14px;
}

.nav-link.active {
  color: #3f88c1;
  background-color: none;
  border-bottom: 6px solid;
  border-radius: 0;
  background: none;
}

.modal-footer {
  border-top: 2px solid #98c4e6 !important;
  margin-top: 20px;
}
</style>
